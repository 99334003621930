<template>
  <div class="pb-5">
      <div class="row">
          <div class="col-md-4">
              <div class="report-card bg-white d-flex flex-column">
                  <div class="d-flex align-items-center">
                      <img src="@/assets/icons/shopping-icon.svg" alt="">
                      <!-- <span class="color-primary font-10  prospect d-flex align-items-center ms-2">
                          <img src="@/assets/icons/arrow-up-icon.svg" alt="">
                          <span>12%</span>

                      </span> -->
                  </div>
                  <span class="font-roboto font-300 font-28" style="color: #495057">
                    {{shift.total_spending > shift.total_income ? '-' : ''}} 
                    {{formatRupiah(shift.total_income - shift.total_spending, 'Rp.')}}
                    </span>
                  <span class="font-roboto font-900 font-10" style="color: #9CA1AA">TOTAL PENDAPATAN BERSIH HARI INI</span>

              </div>
          </div>
          <div class="col-md-4">
              <div class="report-card bg-white d-flex flex-column">
                  <div class="d-flex align-items-center">
                      <img src="@/assets/icons/shopping-icon.svg" alt="">
                      <!-- <span class="color-primary font-10  prospect d-flex align-items-center ms-2">
                          <img src="@/assets/icons/arrow-up-icon.svg" alt="">
                          <span>12%</span>

                      </span> -->
                  </div>
                  <span class="font-roboto font-300 font-28" style="color: #495057">{{formatRupiah(shift.total_income, 'Rp.')}}</span>
                  <span class="font-roboto font-900 font-10" style="color: #9CA1AA">TOTAL PENDAPATAN HARI INI</span>

              </div>
          </div>
          <div class="col-md-4">
              <div class="report-card bg-white d-flex flex-column">
                  <div class="d-flex align-items-center">
                      <img src="@/assets/icons/shopping-icon.svg" alt="">
                      <!-- <span class="color-primary font-10  prospect d-flex align-items-center ms-2">
                          <img src="@/assets/icons/arrow-up-icon.svg" alt="">
                          <span>12%</span>

                      </span> -->
                  </div>
                  <span class="font-roboto font-300 font-28" style="color: #495057">{{formatRupiah(shift.total_spending, 'Rp.')}}</span>
                  <span class="font-roboto font-900 font-10" style="color: #9CA1AA">TOTAL PENGELUARAN HARI INI</span>

              </div>
          </div>
      </div>
      <div class="d-flex mt-4 align-items-center gap-3">
          <div class="flex-grow-1 search-element">
              <el-input v-model="searchKeyword" @change="getReportList('reset')"
                  placeholder="Cari Transaksi" class="search-grey search-transaction w-100 h-100">
                  <img slot="prefix" class="icon-search" src="@/assets/icons/icon-search.svg" alt="Icon Search">
              </el-input>
          </div>
          <div class="d-flex gap-2">
              <button class="button button--primary p-2 px-4" @click="exportData">
                  Export Laporan
              </button>
              <button ref="openOperationalCostModal" class="button button--outline-primary-2  p-2 px-4" data-bs-toggle="modal" href="#operationalCost" role="button">
                  Tambah Biaya Operasional
              </button>
          </div>
      </div>
      <div class="table-container mt-3">
          <table class="table bg-white table-borderless" v-infinite-scroll="getReportList" style="overflow:auto; height: 100px;" :infinite-scroll-delay="1000"  :infinite-scroll-disabled="!shiftTransaction.total || page + 1 > shiftTransaction.last_page" >
              <thead>
                  <tr>
                      <th scope="col">Jumlah</th>
                      <th scope="col">Jenis</th>
                      <th scope="col">Keterangan</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="transaction in shiftTransaction.data" :key="transaction.id">
                      <td style="width: 20%;">{{formatRupiah(transaction.amount, 'Rp.')}}</td>
                      <td style="width: 20%;" :class="transaction.type === 'income' ? 'color-primary' : 'color-red'">{{transaction.type === 'income' ? 'Pemasukan' : 'Pengeluaran'}}</td>
                      <td style="width: 60%;">{{transaction.note}}</td>
                  </tr>
                  <tr v-if="!shiftTransaction.data.length">
                    <td colspan="3" class="text-center align-middle">Tidak ada laporan</td>
                  </tr>
                  <tr  v-if="shiftTransactionLoading">
                      <td colspan="3">
                          <div class="spinner-border color-primary mx-auto d-block" role="status" >
                            <span class="visually-hidden">Loading...</span>
                        </div>   
                      </td>   
                  </tr>    
              </tbody>
          </table>
      </div>
    <operational-cost ref="operationalCostModal" @addedCost="addedCost" />
    <SelectImageInputType  @selectUpload="uploadSelected" />

  </div>
</template>
<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import { saveAs } from 'file-saver';
import IhsMixin from '@/mixins/ihs.mixins.js'

import OperationalCost from "./popup/OperationalCost.vue";
import SelectImageInputType from '../base/SelectImageInputType.vue';
export default {
    mixins: [IhsMixin],
    components : {
        OperationalCost,
        SelectImageInputType
    },
    data() {
        return {
            searchKeyword: '',
            page: 0
        }
    },
    computed: {
        ...mapState('home', ['sidebarVisible']),
        ...mapState('shift', ['shiftTransactionLoading' , 'shiftTransaction', 'shiftId', 'shift'])
    },
    methods : {
        ...mapActions('shift', ['exportShiftTransactionDaily', 'getShiftListTransaction', 'getShift']),
        ...mapMutations('shift', ['SET_SHIFT_TRANSACTION_LOADING']),
        async addedCost() {
            await this.getReportList('reset')
            await this.getShift();
        },
        async getReportList (type) {
            if(type === 'reset') {
                this.page = 1
            } else {
                this.page++
            }
            this.SET_SHIFT_TRANSACTION_LOADING(true)
            const id  = localStorage.getItem('active-shift-id')
            
             await this.getShiftListTransaction({
                data: {
                    shift_id: id
                },
                params: {
                    page: this.page,
                    perPage: 14,
                    search: this.searchKeyword,
                    shift_id : this.shiftId ? this.shiftId : ''
                }
            }) 

            if (this.page < this.shiftTransaction.last_page) {
                this.SET_SHIFT_TRANSACTION_LOADING(true)
            }
        },
        async exportData() {
            const id  = localStorage.getItem('active-shift-id')
            
            const response = await this.exportShiftTransactionDaily(id)
            if(response.status === 200) {
                saveAs(response.data , `transaction-${new Date()}.xlsx`)
            }
        },
        async uploadSelected(type) {

            await this.$refs.operationalCostModal.$refs.imageInput.uploadSelected(type)
        },
    
    },
    mounted () {
        this.getReportList();
        this.getShift();
    }
};
</script>
<style lang="scss">
  .search-transaction {
      .el-input__inner {
          height: 100% !important;
          border: 0 !important;
      }
  }
  
</style>
<style lang="scss" scoped>
  .page-wrapper {
    background: #F5F5F5;
    transition: margin 400ms ease-in-out;
    overflow-x: hidden;
    position: relative;
    &.onSidebarOpen {
      margin-left: 78px;
    }
  }


  .report-card {
    box-shadow: 0px 8px 16px 0px #89898926;
    border-radius: 8px;
    padding: 12px;
  }

  .prospect {
      background-color: #DFF9ED;
      padding: 4px;
      border-radius: 4px;
  }

  .table-container {
      background-color: white;
      padding: 10px 24px 10px 24px;
      border-radius: 8px;
  }

  thead tr{
      border-bottom: 1px solid #E0E0E0
  }

  .button {
      height: 44px !important;
  }
</style>

